<template>
  <app-header/>
    <Suspense>
      <template #default>
         <app-adventure/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../../components/layouts/AppFooter.vue'
import AppHeader from '../../components/layouts/AppHeader.vue'
import AppAdventure from '../../components/Pages/GamesCategorys/AppAdventure.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from 'vue'
export default {
  components: { AppHeader, AppAdventure, AppFooter, AppLoading, Suspense },
  name: 'Adventure'
}
</script>

<style>

</style>